import React from 'react';
import PhotoCollage from '../components/PhotoCollage';
import Header from '../components/Header'; 

function Home() {
  return (
    <div>
      <h1>Anish Goel</h1> 
      <p>
        I'm a 3rd year applied + computational math undergrad at Caltech. 
      </p>
      <p>
        I have worked at startups, wet-labs, non-profits, and investment firms.
      </p>
      <Header /> 
      <h2 className="text">"mailing list"</h2>
      <div>
        If you're cool with me texting or emailing you interesting and funny things I find, please reach out.
      </div>
      <h2 className="text">Photos</h2>
      <PhotoCollage />
    </div>
  );
}

export default Home;